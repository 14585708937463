import depositExpay from "../../api/depositExpay";

export const getExample = (data) => {
  console.log(data)
  
  return {
    type: "DEPOSIT_EXPAY",
    payload: data,
    loading: false,
  }
};

export const loadingExample = () => ({
  type: "LOADING_EXAMPLE",
  loading: true,
});


export const deposit = (amount, token, currency) => (dispatch) => {
  dispatch(loadingExample());
  depositExpay.post(amount, token, currency).then((data) => (dispatch(getExample(data))))
}
